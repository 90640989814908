<template>
  <el-menu
    class="el-menu-vertical-demo"
    default-active="1"
    :router="true"
    background-color="#2a3042"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <el-menu-item index="1" :route="{ path: '/dashboard' }">
      <el-icon><House /></el-icon>
      <span>Dashboard</span>
    </el-menu-item>
    <el-menu-item index="2" :route="{ path: '/survey' }">
      <el-icon><DocumentChecked /></el-icon>
      <span>Surveys</span>
    </el-menu-item>
    <el-menu-item index="3" :route="{ path: '/sales/import' }">
      <el-icon><Download /></el-icon>
      <span>Sales Import</span>
    </el-menu-item>
    <el-menu-item index="4" :route="{ path: '/collection/import' }">
      <el-icon><Download /></el-icon>
      <span>Collection Import</span>
    </el-menu-item>
    <el-menu-item index="5" :route="{ path: '/dump/data' }">
      <el-icon><Memo /></el-icon>
      <span>Dump data</span>
    </el-menu-item>
    <el-menu-item index="6" :route="{ path: '/targets' }">
      <el-icon><Aim /></el-icon>
      <span>Targets</span>
    </el-menu-item>
    <el-sub-menu index="7">
      <template #title>
        <el-icon><Setting /></el-icon>
        <span>Setting</span>
      </template>
      <!-- <el-menu-item> -->
      <el-menu-item index="7-1" :route="{ path: '/setting/sku' }"
        >SKU</el-menu-item
      >
      <el-menu-item index="7-2" :route="{ path: '/setting/category' }"
        >Category</el-menu-item
      >
      <el-menu-item index="7-3" :route="{ path: '/setting/products' }"
        >Product</el-menu-item
      >
      <!-- </el-menu-item-group> -->
    </el-sub-menu>
    <el-sub-menu index="8">
      <template #title>
        <el-icon><Service /></el-icon>
        <span>CRM</span>
      </template>
      <!-- <el-menu-item> -->
      <el-menu-item index="8-1" :route="{ path: '/crm/call-log' }">
        <span>Call Log</span>
      </el-menu-item>

      <el-menu-item index="8-2" :route="{ path: '/crm/call-category' }">
        <span>Call Category</span>
      </el-menu-item>

      <el-menu-item index="8-3" :route="{ path: '/crm/call-status' }">
        <span>Call Status</span>
      </el-menu-item>
      <!-- </el-menu-item-group> -->
    </el-sub-menu>
    <el-menu-item index="9" :route="{ path: '/consumer' }">
      <el-icon><User /></el-icon>
      <span>Consumer List</span>
    </el-menu-item>
  </el-menu>
</template>
<script>
export default {
  name: "SidebarComp",
};
</script>
<style lang="scss">
.el-menu-vertical-demo {
  // height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  height: 100%;
}

.el-menu {
  .el-sub-menu,
  .el-menu-item {
    &.is-active {
      background: rgb(34, 38, 53);
      color: #ffd04b;
    }
  }

  .el-sub-menu {
    &.is-opend,
    &.is-active {
      .el-sub-menu__title {
        background: rgb(34, 38, 53) !important;
        color: #ffd04b !important;
      }
    }
  }
}
.ml-10 {
  margin-left: 10px;
}
</style>
