import { createRouter, createWebHistory } from "vue-router";
import AppLayout from "@/layouts/App.vue";
import PublicLayout from "@/layouts/Public.vue";
import store from "@/store/index";

const routes = [
  {
    path: "",
    component: PublicLayout,
    redirect: "/login",
    children: [
      {
        path: "/login",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/pages/Login.vue"),
      },
    ],
  },
  {
    path: "",
    component: AppLayout,
    meta: { auth: true },
    children: [
      {
        path: "/dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
      },
      {
        path: "/survey",
        component: () =>
          import(/* webpackChunkName: "survey" */ "../views/Surveys.vue"),
      },
      {
        path: "/sales/import",
        component: () =>
          import(
            /* webpackChunkName: "sales-import" */ "../views/import/Import.vue"
          ),
      },
      {
        path: "/collection/import",
        component: () =>
          import(
            /* webpackChunkName: "collection-import" */ "../views/import/CollectionImport.vue"
          ),
      },
      {
        path: "/dump/data",
        component: () =>
          import(
            /* webpackChunkName: "dump-data" */ "../views/import/DumpData.vue"
          ),
      },
      {
        path: "/targets",
        component: () =>
          import(
            /* webpackChunkName: "sales-target" */ "../views/targets/List.vue"
          ),
      },
      {
        path: "/targets/create",
        component: () =>
          import(
            /* webpackChunkName: "sales-target-create" */ "../views/targets/CreateUpdate.vue"
          ),
      },
      {
        path: "/target/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "sales-target-edit" */ "../views/targets/CreateUpdate.vue"
          ),
      },
      {
        path: "/targets/update/:id",
        component: () =>
          import(
            /* webpackChunkName: "targets-update" */ "../views/targets/CreateUpdate.vue"
          ),
      },
      {
        path: "/setting/sku",
        component: () =>
          import(/* webpackChunkName: "sku" */ "../views/sku/Index.vue"),
      },
      {
        path: "/setting/category",
        component: () =>
          import(
            /* webpackChunkName: "category" */ "../views/products/Category.vue"
          ),
      },
      {
        path: "/setting/products",
        component: () =>
          import(
            /* webpackChunkName: "products" */ "../views/products/Products.vue"
          ),
      },
      {
        path: "/crm/call-log",
        component: () =>
          import(/* webpackChunkName: "call-log" */ "../views/crm/CallLog.vue"),
      },
      {
        path: "/crm/call-category",
        component: () =>
          import(
            /* webpackChunkName: "call-category" */ "../views/crm/CallCategory.vue"
          ),
      },
      {
        path: "/crm/call-status",
        component: () =>
          import(
            /* webpackChunkName: "call-status" */ "../views/crm/CallStatus.vue"
          ),
      },
      {
        path: "/consumer",
        component: () =>
          import(
            /* webpackChunkName: "call-status" */ "../views/consumer/ConsumerList.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.auth) &&
    !store.getters["Auth/isLoggedIn"]
  ) {
    next("/login");
    return;
  }
  next();
});

export default router;
