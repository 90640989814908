import axios from "axios";

const state = {
  isLoggedIn: false,
  authToken: "",
  authUser: {},
};

const getters = {
  isLoggedIn: (state) => {
    return state.authToken ? true : false;
  },
  authToken: (state) => {
    return state.authToken;
  },
  authUser: (state) => {
    return state.authUser;
  },
};

const actions = {
  login({ commit }, form) {
    return axios.post("/user/login?platform=web", form).then((res) => {
      if (res.data.status_code == 200) {
        commit("SET_TOKEN", res.data.user);
        commit("SET_USER", res.data.user);
      }
      return res;
    });
  },
  logout({ commit }) {
    commit("REMOVE_TOKEN");
    commit("REMOVE_USER");
  },
};

const mutations = {
  SET_TOKEN(state, user) {
    state.authToken = user.api_token;
  },
  SET_USER(state, user) {
    state.authUser = user;
  },
  REMOVE_TOKEN(state) {
    state.authToken = "";
  },
  REMOVE_USER(state) {
    state.authUser = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
