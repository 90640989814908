import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import Auth from "./modules/auth";
export default createStore({
  plugins: [createPersistedState()],
  // state: {},
  // getters: {},
  // mutations: {},
  // actions: {},
  modules: {
    Auth,
  },
});
