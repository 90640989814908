<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "PublicLayout",
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800&display=swap");
* {
  font-family: "Poppins", sans-serif;
}
body {
  background-color: #ebedef;
  margin: 0;
}
</style>
