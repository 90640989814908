import { createApp } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL + "/";

axios.interceptors.response.use((response) => {
  if (response.data && response.data.status_code == 401) {
    store.dispatch("Auth/logout");
    window.location = "/login";
  }
  return response;
});

app.use(VueAxios, axios);
app.use(ElementPlus);
app.use(store).use(router).mount("#app");
